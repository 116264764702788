<template>
  <div class="section-block">
    <BaseElFormItem label="會員權益包含的項目" style="width: 560px;" prop="entitlements">
      <template #label>
        <div class="w-full inline-flex items-center justify-between">
          <p>會員權益包含的項目</p>
          <el-popover
            v-model="popover.resources"
            placement="bottom-end"
            :visible-arrow="false"
            :offset="10"
            width="200"
            trigger="click"
          >
            <div>
              <p
                v-for="item in resourceOptions"
                :key="item.value"
                class="resource-option"
                @click="onSelectResource(item.value)"
              >
                {{ item.label }}
              </p>
            </div>

            <BaseElButton slot="reference" type="text" class="py-0">
              新增
              <i class="el-icon-plus " />
            </BaseElButton>
          </el-popover>
        </div>
      </template>

      <div class="flex flex-col gap-[20px]">
        <div v-for="(resource, idx) in syncFormData.entitlements" :key="idx">
          <div
            v-if="['classTicket', 'pointCard', 'punchCard', 'coupon'].includes(resource.standardAward.type)"
            class="flex items-center gap-[8px]"
            style="max-width: 580px;"
          >
            <BaseElFormItem class="w-full" :prop="`entitlements[${idx}].standardAward.awardId`">
              <ElInputWrapper>
                <BaseElSelect
                  v-model="resource.data"
                  popper-class="select-popper"
                  :placeholder="selectInputPlaceholder(resource.standardAward.type)"
                  @click.native="onClickSelector(idx, resource.standardAward.type)"
                  @visible-change="onOpenSelector(resource.standardAward.type)"
                >
                  <BaseElSelectOption
                    :label="findResourceName(resource.standardAward.awardId, resource.standardAward.type)"
                    :value="formData.couponId"
                  />
                </BaseElSelect>
              </ElInputWrapper>
            </BaseElFormItem>
            <BaseElFormItem class="w-full" :prop="`entitlements[${idx}].standardAward.amount`">
              <ElInputWrapper>
                <BaseElInput
                  v-model="resource.standardAward.amount"
                  placeholder="請輸入"
                >
                  <template #suffix>
                    <template v-if="['classTicket', 'punchCard'].includes(resource.standardAward.type)">
                      <span>份</span>
                    </template>
                    <template v-else-if="['pointCard', 'coupon'].includes(resource.standardAward.type)">
                      <span>張</span>
                    </template>
                  </template>
                </BaseElInput>
              </ElInputWrapper>
            </BaseElFormItem>

            <div class="flex items-center cursor-pointer" @click="removeResource(idx)">
              <SVGIcon icon="common-trash" fill="var(--gray-60)" />
            </div>
          </div>

          <div v-if="['cashback','wallet','point',].includes(resource.standardAward.type)">
            <BaseElFormItem :prop="`entitlements[${idx}].standardAward.amount`">
              <div class="flex items-center gap-[8px]">
                <ElInputWrapper class="w-full" style="max-width: 552px">
                  <BaseElInput
                    v-model="resource.standardAward.amount"
                    type="number"
                    placeholder="請輸入"
                  >
                    <template #suffix>
                      <template v-if="resource.standardAward.type === 'cashback'">
                        <span>回饋金（元）</span>
                      </template>
                      <template v-else-if="resource.standardAward.type === 'wallet'">
                        <span>儲值金（元）</span>
                      </template>
                      <template v-else-if="resource.standardAward.type === 'point'">
                        <span>點數（點）</span>
                      </template>
                    </template>
                  </BaseElInput>
                </ElInputWrapper>
                <div class="flex items-center cursor-pointer" @click="removeResource(idx)">
                  <SVGIcon icon="common-trash" fill="var(--gray-60)" />
                </div>
              </div>
            </BaseElFormItem>
          </div>
        </div>

        <GrayBlockContainer v-show="!syncFormData.entitlements.length">
          請點擊右上角增加權益項目
        </GrayBlockContainer>
      </div>
    </BaseElFormItem>

    <ClassTicketAdvanceSelect
      v-if="modal.classTicketSelector"
      :data="resourceData.classTicket"
      :disabledExp="true"
      @close="modal.classTicketSelector = false"
      @confirm="updateResourceData"
    />
    <CouponAdvanceSelect
      v-if="modal.couponSelector"
      :data="resourceData.coupon"
      :typeOptions="{
        coupon: {label: '使用券', value: 'coupon'},
        exchange: {label: '兌換券', value: 'exchange'},
      }"
      @close="modal.couponSelector = false"
      @confirm="updateResourceData"
    />
    <BaseAdvanceSelect
      v-if="modal.pointCardSelector"
      :data="resourceData.pointCard"
      @close="modal.pointCardSelector = false"
      @confirm="updateResourceData"
    />
    <BaseAdvanceSelect
      v-if="modal.punchCardSelector"
      :data="resourceData.punchCard"
      @close="modal.punchCardSelector = false"
      @confirm="updateResourceData"
    />
  </div>
</template>

<script>
import GrayBlockContainer from '@/components/Container/GrayBlockContainer.vue'
import { useVModel } from '@vueuse/core'
import { computed, defineComponent, inject, onBeforeMount, reactive, ref } from 'vue'
import ElInputWrapper from '@/components/ElInputWrapper.vue'
import { useFetch } from '@/use/fetch'
import { GetClassTicket } from '@/api/classTicket'
import { GetCoupon } from '@/api/lottery/coupon'
import { GetPointCard } from '@/api/pointCard'
import { GetPunchCard } from '@/api/punchCard'
import { usePermissions } from '@/use/permissions'
import { useShop } from '@/use/shop'
import { filter, some, map } from 'lodash'
import ClassTicketAdvanceSelect from '@/components/Select/ClassTicketAdvanceSelect.vue'
import CouponAdvanceSelect from '@/components/Select/CouponAdvanceSelect.vue'
import BaseAdvanceSelect from '@/components/Select/BaseAdvanceSelect .vue'
import formUtils from '@/utils/form'
import { useRoute } from 'vue-router/composables'

export default defineComponent({
  name: 'BenefitFormBlock',
  components: {
    GrayBlockContainer,
    ElInputWrapper,
    ClassTicketAdvanceSelect,
    CouponAdvanceSelect,
    BaseAdvanceSelect,
  },
  props: {
    formData: {
      type: Object,
      default: () => ({}),
    },
  },
  setup (props, { emit }) {
    const formRef = inject('formRef')
    const copyOriginData = inject('copyOriginData')
    const route = useRoute()
    const syncFormData = useVModel(props, 'formData', emit)
    const { fetchAll } = useFetch()
    const { shopId } = useShop()
    const { checkAction } = usePermissions()
    const popover = reactive({
      resources: false,
    })
    const modal = reactive({
      classTicketSelector: false,
      couponSelector: false,
      punchCardSelector: false,
      pointCardSelector: false,
    })
    const resourceData = reactive({
      classTicket: [],
      coupon: [],
      punchCard: [],
      pointCard: [],
    })

    const dataPlaceholder = reactive({
      itemIdx: null,
      type: null,
    })

    const useFeatures = computed(() => {
      return {
        classTicket: checkAction('admin.classTicket.page'),
        coupon: checkAction('admin.coupon.page'),
        punchCard: checkAction('admin.punchCard.page'),
        pointCard: checkAction('admin.pointCard.page'),
      }
    })

    const selectInputPlaceholder = computed(() => {
      return (type) => {
        if (type === 'classTicket') return '請選擇堂票'
        else if (type === 'punchCard') return '請選擇次卡'
        else if (type === 'pointCard') return '請選擇點數卡'
        else if (type === 'coupon') return '請選擇票券'
        return ''
      }
    })

    const getResouceData = async () => {
      const callList = []
      if (useFeatures.value.classTicket) {
        callList.push(async () => {
          await fetchAll(GetClassTicket, { shopId: shopId.value }, (res) => {
            resourceData.classTicket = res
          })
        })
      }
      if (useFeatures.value.coupon) {
        callList.push(async () => {
          await fetchAll(GetCoupon, { shopId: shopId.value, type: 'all' }, (res) => {
            resourceData.coupon = filter(res, (item) => ['coupon', 'exchange'].includes(item.type))
          })
        })
      }
      if (useFeatures.value.punchCard) {
        callList.push(async () => {
          await fetchAll(GetPunchCard, { shopId: shopId.value }, (res) => {
            resourceData.punchCard = res
          })
        })
      }
      if (useFeatures.value.pointCard) {
        callList.push(async () => {
          await fetchAll(GetPointCard, { shopId: shopId.value }, (res) => {
            resourceData.pointCard = res
          })
        })
      }

      await Promise.all(callList.map((call) => call()))
    }

    const resourceOptions = computed(() => {
      let options = [
        { label: '堂票', value: 'classTicket' },
        { label: '點數卡', value: 'pointCard' },
        { label: '次卡', value: 'punchCard' },
        { label: '票券', value: 'coupon' },
        { label: '回饋金', value: 'cashback' },
        { label: '儲值金', value: 'wallet' },
        { label: '點數', value: 'point' },
      ]
      if (!useFeatures.value.classTicket) options = filter(options, (o) => o.value !== 'classTicket')
      if (!useFeatures.value.coupon) options = filter(options, (o) => o.value !== 'coupon')
      if (!useFeatures.value.punchCard) options = filter(options, (o) => o.value !== 'punchCard')
      if (!useFeatures.value.pointCard) options = filter(options, (o) => o.value !== 'pointCard')
      if (some(syncFormData.value.entitlements, { standardAward: { type: 'cashback' } })) options = filter(options, (o) => o.value !== 'cashback')
      if (some(syncFormData.value.entitlements, { standardAward: { type: 'wallet' } })) options = filter(options, (o) => o.value !== 'wallet')
      if (some(syncFormData.value.entitlements, { standardAward: { type: 'point' } })) options = filter(options, (o) => o.value !== 'point')

      return options
    })

    const removeResource = (idx) => {
      syncFormData.value.entitlements.splice(idx, 1)
    }

    const onClickSelector = (idx, type) => {
      dataPlaceholder.itemIdx = idx
      dataPlaceholder.type = type
    }

    const updateResourceData = (data) => {
      const target = syncFormData.value.entitlements[dataPlaceholder.itemIdx]
      if (target) {
        target.standardAward.awardId = data[0]
        formUtils.clearValidate(formRef.value, [`entitlements[${dataPlaceholder.itemIdx}].standardAward.awardId`])
      }
    }

    const onSelectResource = (value) => {
      formUtils.clearValidate(formRef.value, ['entitlements'])
      popover.resources = false

      const data = {
        standardAward: {
          type: value,
          amount: null,
          awardId: undefined,
        },
      }

      // if (value === 'cashback') {
      //   data.standardAward.name = '回饋金'
      // } else if (value === 'wallet') {
      //   data.standardAward.name = '儲值金'
      // } else if (value === 'point') {
      //   data.standardAward.name = '點數'
      // }

      syncFormData.value.entitlements.push(data)

      // 重新排序 syncFormData.value.entitlements，type 為 cashback, wallet, point 的放最後
      syncFormData.value.entitlements.sort((a, b) => {
        if (['cashback', 'wallet', 'point'].includes(a.standardAward.type)) return 1
        if (['cashback', 'wallet', 'point'].includes(b.standardAward.type)) return -1
        return 0
      })
    }

    const onOpenSelector = (type) => {
      if (type === 'classTicket') modal.classTicketSelector = true
      else if (type === 'coupon') modal.couponSelector = true
      else if (type === 'punchCard') modal.punchCardSelector = true
      else if (type === 'pointCard') modal.pointCardSelector = true
    }

    const findResourceName = (id, type) => {
      if (type === 'classTicket') {
        return resourceData.classTicket.find((item) => item.id === id)?.name
      } else if (type === 'coupon') {
        return resourceData.coupon.find((item) => item.id === id)?.name
      } else if (type === 'punchCard') {
        return resourceData.punchCard.find((item) => item.id === id)?.name
      } else if (type === 'pointCard') {
        return resourceData.pointCard.find((item) => item.id === id)?.name
      }
    }

    const findResourceId = (id, type) => {
      if (type === 'classTicket') {
        return resourceData.classTicket.find((item) => item.id === id)?.id
      } else if (type === 'coupon') {
        return resourceData.coupon.find((item) => item.id === id)?.id
      } else if (type === 'punchCard') {
        return resourceData.punchCard.find((item) => item.id === id)?.id
      } else if (type === 'pointCard') {
        return resourceData.pointCard.find((item) => item.id === id)?.id
      }
    }

    const handleCopy = () => {
      const isCopy = route.query.isCopy
      if (!isCopy) return
      syncFormData.value.entitlements = map(copyOriginData.value.entitlements, (item) => {
        const awardId = findResourceId(item.standardAwardId, item.StandardAward.type)
        return {
          standardAward: {
            type: item.StandardAward.type,
            awardId,
            amount: item.StandardAward.amount,
          },
        }
      })
    }

    onBeforeMount(async () => {
      await getResouceData()
      handleCopy()
    })

    return {
      modal,
      resourceOptions,
      onOpenSelector,
      popover,
      onSelectResource,
      syncFormData,
      selectInputPlaceholder,
      resourceData,
      removeResource,
      updateResourceData,
      onClickSelector,
      findResourceName,
      dataPlaceholder,
    }
  },
})

</script>

<style lang="postcss" scoped>
::v-deep .el-form-item__label {
  @apply w-full inline-flex;
}

.resource-option {
  @apply cursor-pointer py-[8px] px-[12px] ;
  @apply hover:bg-primary-100 hover:text-white;
}
</style>
