import { admin2 } from './instance'

// --- Record ---

// GET admin.periodicBenefitRecord.find
export const GetPeriodicBenefitRecord = async ({
  shopId,
  code,
  name,
  status,
  MemberId,
  start,
  limit,
}) => {
  return await admin2({
    url: `/${shopId}/periodicBenefitRecord`,
    method: 'GET',
    params: {
      code,
      name,
      status,
      MemberId,
      start,
      limit,
    },
  })
}

// GET admin.periodicBenefitRecord.count
export const GetPeriodicBenefitRecordCount = async ({
  shopId,
  code,
  name,
  status,
  MemberId,
}) => {
  return await admin2({
    url: `/${shopId}/periodicBenefitRecord/count`,
    method: 'GET',
    params: {
      code,
      name,
      status,
      MemberId,
    },
  })
}

// GET admin.periodicBenefitRecord.findOne
export const FindPeriodicBenefitRecord = async ({
  shopId,
  id,
}) => {
  return await admin2({
    url: `/${shopId}/periodicBenefitRecord/${id}`,
    method: 'GET',
  })
}

// POST admin.periodicBenefitRecord.create
export const CreatePeriodicBenefitRecord = async ({
  shopId,
  memberId,
  periodicBenefitTemplateId,
  price,
  note,
}) => {
  return await admin2({
    url: `/${shopId}/periodicBenefitRecord`,
    method: 'POST',
    data: {
      memberId,
      periodicBenefitTemplateId,
      price,
      note,
    },
  })
}

// PUT admin.periodicBenefitRecord.pause
export const PausePeriodicBenefitRecord = async ({
  shopId,
  id,
}) => {
  return await admin2({
    url: `/${shopId}/periodicBenefitRecord/${id}/pause`,
    method: 'PUT',
  })
}

// PUT admin.periodicBenefitRecord.resume
export const ResumePeriodicBenefitRecord = async ({
  shopId,
  id,
}) => {
  return await admin2({
    url: `/${shopId}/periodicBenefitRecord/${id}/resume`,
    method: 'PUT',
  })
}

// PUT admin.periodicBenefitRecord.terminate
export const TerminatePeriodicBenefitRecord = async ({
  shopId,
  id,
}) => {
  return await admin2({
    url: `/${shopId}/periodicBenefitRecord/${id}/terminate`,
    method: 'PUT',
  })
}

// GET admin.periodicBenefitRecord.getTimeline
export const GetPeriodicBenefitRecordTimeline = async ({
  shopId,
  id,
}) => {
  return await admin2({
    url: `/${shopId}/periodicBenefitRecord/${id}/timeline`,
    method: 'GET',
  })
}

// --- Template ---

// GET admin.periodicBenefitTemplate.find
export const GetPeriodicBenefitTemplate = async ({
  shopId,
  start,
  limit,
  name,
}) => {
  return await admin2({
    url: `/${shopId}/periodicBenefitTemplate`,
    method: 'GET',
    params: {
      start,
      limit,
      name,
    },
  })
}

// GET admin.periodicBenefitTemplate.count
export const GetPeriodicBenefitTemplateCount = async ({
  shopId,
  name,
}) => {
  return await admin2({
    url: `/${shopId}/periodicBenefitTemplate/count`,
    method: 'GET',
    params: {
      name,
    },
  })
}

// GET admin.periodicBenefitTemplate.findOne
export const FindPeriodicBenefitTemplate = async ({
  shopId,
  id,
}) => {
  return await admin2({
    url: `/${shopId}/periodicBenefitTemplate/${id}`,
    method: 'GET',
  })
}

// PUT admin.periodicBenefitTemplate.update
export const UpdatePeriodicBenefitTemplate = async ({
  shopId,
  id,
  name,
  period,
  imageId,
  order,
}) => {
  return await admin2({
    url: `/${shopId}/periodicBenefitTemplate/${id}`,
    method: 'PUT',
    data: {
      name,
      period,
      imageId,
      order,
    },
  })
}

// POST admin.periodicBenefitTemplate.create
export const CreatePeriodicBenefitTemplate = async ({
  shopId,
  type,
  name,
  description,
  order,
  period,
  totalAwardCount,
  instantAward,
  conditions,
  failConditionCount,
  imageId,
  entitlements,
}) => {
  return await admin2({
    url: `/${shopId}/periodicBenefitTemplate`,
    method: 'POST',
    data: {
      type,
      name,
      description,
      order,
      period,
      totalAwardCount,
      instantAward,
      conditions,
      failConditionCount,
      imageId,
      entitlements,
    },
  })
}

// DELETE admin.periodicBenefitTemplate.destroy
export const DeletePeriodicBenefitTemplate = async ({
  shopId,
  id,
}) => {
  return await admin2({
    url: `/${shopId}/periodicBenefitTemplate/${id}`,
    method: 'DELETE',
  })
}

// --- Config ---
// GET admin.periodicBenefitConfig.singleFind
export const GetPeriodicBenefitConfig = async ({
  shopId,
}) => {
  return await admin2({
    url: `/${shopId}/periodicBenefitConfig`,
    method: 'GET',
  })
}

// PUT admin.periodicBenefitConfig.singleUpdate
export const UpdatePeriodicBenefitConfig = async ({
  shopId,
  subscriptionDecideAward,
}) => {
  return await admin2({
    url: `/${shopId}/periodicBenefitConfig`,
    method: 'PUT',
    data: {
      subscriptionDecideAward,
    },
  })
}
