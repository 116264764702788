<template>
  <div class="section-block">
    <p class="text-primary-100 font-medium text-md pb-[12px]">發放週期</p>
    <template v-if="syncFormData.type === 'subscription'">
      <BaseElFormItem label="依照訂閱方案的週期設定">
        <div>
          <BaseElSwitch
            v-model="syncFormData.isSubscription"
            active-text="是"
            inactive-text="否"
          />
          <GrayBlockContainer v-show="syncFormData.isSubscription">
            請於完成設定會員權益範本後，至「訂閱模組 > 新增 / 編輯訂閱方案」將此會員權益範本選擇為訂閱品項，此會員權益的發放週期就會比照訂閱方案的週期設定 ( 單次訂閱時長 + 扣款時機設定 )——「訂閱方案期數」等於「會員權益發放次數」，「每期訂閱方案時長」等於「每次會員權益發放時間間隔」。
          </GrayBlockContainer>
        </div>
      </BaseElFormItem>

      <div v-if="!syncFormData.isSubscription">
        <BaseElFormItem>
          <template #label>
            <FormItemTooltipLabel label="預覽訂閱方案的週期設定" :tooltipWidth="200">
              將依據現有訂閱方案的週期，預設一組週期完全吻合的會員權益發放週期
            </FormItemTooltipLabel>
          </template>
          <BaseSearch
            :value.sync="syncFormData.subscribePlan"
            :api="GetSubscribePlan"
            placeholder="請選擇"
          />
        </BaseElFormItem>

        <GrayBlockContainer>
          <FormInfo gap="10">
            <FormInfoItem
              v-for="info in previewSubscriptionPeriod"
              :key="info.label"
              width="132"
              :title="info.label"
              :content="info.value"
            />
          </FormInfo>
        </GrayBlockContainer>
      </div>
    </template>

    <template v-if="!syncFormData.isSubscription || syncFormData.type === 'standard'">
      <p class="font-medium text-gray-100 mt-[20px] mb-[8px]"><span class="text-danger ">*</span>會員權益發放週期</p>
      <GrayBlockContainer>
        <BaseElFormItem prop="period.startType">
          <BaseElRadioGroup v-model="syncFormData.period.startType">
            <BaseElRadio label="instant">依照會員獲得會員權益的日期</BaseElRadio>
            <BaseElRadio label="fixed">依照固定日期</BaseElRadio>
          </BaseElRadioGroup>
        </BaseElFormItem>

        <BaseElFormItem label="發放週期" prop="period.type">
          <BaseElSelect v-model="syncFormData.period.type" @change="onPeriodTypeChange">
            <BaseElSelectOption
              v-for="period in givePeriodOptions"
              :key="period.value"
              :label="period.label"
              :value="period.value"
            />
          </BaseElSelect>
        </BaseElFormItem>
        <BaseElFormItem v-if="showFields.fixedDate" label="發放日期" prop="period.fixedValue">
          <BaseElSelect v-model="syncFormData.period.fixedValue">
            <BaseElSelectOption
              v-for="period in fixedDateOptions"
              :key="period.value"
              :label="period.label"
              :value="period.value"
            />
          </BaseElSelect>
        </BaseElFormItem>
        <BaseElFormItem v-if="showFields.fixedWeekDay" label="發送的星期" prop="period.fixedWeekDay">
          <BaseElSelect v-model="syncFormData.period.fixedWeekDay">
            <BaseElSelectOption
              v-for="period in weekDayOptions"
              :key="period.label"
              :label="period.label"
              :value="period.value"
            />
          </BaseElSelect>
        </BaseElFormItem>
        <BaseElFormItem v-if="showFields.nDay" label="週期天數" prop="period.nDayValue">
          <BaseElInput v-model="syncFormData.period.nDayValue" placeholder="請輸入數字">
            <template #suffix>天</template>
          </BaseElInput>
        </BaseElFormItem>
        <BaseElFormItem label="發放總次數" prop="totalAwardCount">
          <BaseElInput v-model="syncFormData.totalAwardCount" type="number" placeholder="請輸入數字">
            <template #suffix>次</template>
          </BaseElInput>
        </BaseElFormItem>

        <BaseElFormItem v-if="syncFormData.period.startType === 'fixed'" prop="instantAward">
          <template #label>
            <FormItemTooltipLabel :tooltip-width="200" label="會員獲得會員權益當下，立即發放第一次">
              開啟時，會員獲得會員權益當下就會發放 1 次，後續依照固定日期發送。關閉時，權益僅在固定日期發送，若固定每月 1 號發放，在 1/10 取得，第一次會在 2/1 發放
            </FormItemTooltipLabel>
          </template>
          <BaseElSwitch
            v-model="syncFormData.instantAward"
            active-text="是"
            inactive-text="否"
          />
        </BaseElFormItem>

        <div class="whitespace-pre-line text-gray-80" v-html="displayPeriodPreviewText" />

        <div v-show="showPeriodOverflowHint" class="text-danger mt-[20px]">
          會員權益發放總時長超過單次訂閱時長，請留意設定是否正確
        </div>
      </GrayBlockContainer>
    </template>
  </div>
</template>

<script>
import { useVModel } from '@vueuse/core'
import { computed, defineComponent } from 'vue'
import GrayBlockContainer from '@/components/Container/GrayBlockContainer.vue'
import { GetSubscribePlan } from '@/api/subscribe'
import FormItemTooltipLabel from '@/components/Form/FormItemTooltipLabel.vue'
import BaseSearch from '@/components/Search/BaseSearch.vue'
import { get, find, filter, toNumber } from 'lodash'
import FormInfoItem from '@/components/FormInfo/FormInfoItem.vue'
import FormInfo from '@/components/FormInfo/FormInfo.vue'
import { subscribePaymentTimeTypeConfig } from '@/config/subscribe'
import { usePeriodBenefitTemplate } from '@/use/usePeriodBenefit'

export default defineComponent({
  name: 'PeriodFormBlock',
  components: {
    GrayBlockContainer,
    FormItemTooltipLabel,
    BaseSearch,
    FormInfoItem,
    FormInfo,
  },
  props: {
    formData: {
      type: Object,
      default: () => ({}),
    },
  },
  setup (props, { emit }) {
    const syncFormData = useVModel(props, 'formData', emit)
    const { formatPeriodPreviewText, weekDayOptions } = usePeriodBenefitTemplate()

    // 發放週期選項
    const givePeriodOptions = computed(() => {
      // daily weekly monthly nDay
      const options = [
        { label: '每個月發放 1 次', value: 'monthly' },
        { label: '每 n 天發放 1 次', value: 'nDay' },
        { label: '每週發放 1 次', value: 'weekly' },
      ]

      if (syncFormData.value.period.startType === 'fixed') {
        return filter(options, (option) => option.value !== 'nDay')
      }

      return options
    })

    const fixedDateOptions = computed(() => {
      const options = []

      for (let i = 1; i <= 28; i++) {
        options.push({
          label: `${i} 號`,
          value: i,
        })
      }

      options.push({
        label: '每月最後一天',
        value: 32,
      })

      return options
    })

    const showFields = computed(() => {
      const startType = syncFormData.value.period.startType
      const periodType = syncFormData.value.period.type

      const fields = {
        fixedWeekDay: false, // 發送的星期
        nDay: false, // 週期天數
        fixedDate: false, // 週期日期
        totalTime: false, // 發放總次數
      }

      if (startType === 'instant') {
        if (periodType === 'monthly') {
          fields.totalTime = true
          return fields
        } else if (periodType === 'nDay') {
          fields.nDay = true
          fields.totalTime = true
          return fields
        } else if (periodType === 'weekly') {
          fields.totalTime = true
          return fields
        }
      } else if (startType === 'fixed') {
        if (periodType === 'monthly') {
          fields.fixedDate = true
          fields.totalTime = true
          return fields
        } else if (periodType === 'weekly') {
          fields.fixedWeekDay = true
          fields.totalTime = true
          return fields
        }
      }

      return fields
    })

    const previewSubscriptionPeriod = computed(() => {
      const subscribePlan = syncFormData.value.subscribePlan
      const paymenttiming = get(subscribePlan, 'paymentTimeSettings.fields.downPaymentTime', '')
      return [
        { label: '單次訂閱時長', value: get(subscribePlan, 'periodSettings.fields.periods', '-') },
        { label: '單次訂閱期數', value: get(subscribePlan, 'periodSettings.fields.periods', '-') },
        { label: '扣款時機', value: get(subscribePaymentTimeTypeConfig, `${paymenttiming}.label`, '-') },
      ]
    })

    const displayPeriodPreviewText = computed(() => {
      const startType = syncFormData.value.period.startType
      const periodType = syncFormData.value.period.type
      const instantAward = syncFormData.value.instantAward
      const fixedWeekDay = syncFormData.value.period.fixedWeekDay || '-'
      const nDayValue = syncFormData.value.period.nDayValue || '-'
      const fixedValue = syncFormData.value.period.fixedValue || '-'
      const totalAwardCount = syncFormData.value.totalAwardCount || '-'

      return formatPeriodPreviewText({
        startType,
        periodType,
        fixedWeekDay,
        nDayValue,
        fixedValue,
        instantAward,
        totalAwardCount,
      })
    })

    const showPeriodOverflowHint = computed(() => {
      const subscribePlan = syncFormData.value.subscribePlan
      if (!subscribePlan) return false

      const originTotal = getTotalPeriod({
        periods: subscribePlan.periodSettings.fields.periods,
        method: subscribePlan.periodSettings.method,
      })

      const curTotal = getTotalPeriod({
        periods: syncFormData.value.totalAwardCount,
        method: syncFormData.value.period.type,
        nDay: syncFormData.value.period.nDayValue,
      })

      if (curTotal > originTotal) {
        return true
      }

      return false
    })

    const getTotalPeriod = (data) => {
      const methodUnit = {
        monthly: 30,
        weekly: 7,
      }

      const periods = data.periods
      const method = data.method

      let unit = methodUnit[method]
      if (method === 'nDay') {
        unit = data.nDay
      }
      return toNumber(periods) * toNumber(unit)
    }

    const onPeriodTypeChange = () => {
      // syncFormData.value.totalAwardCount = null
    }

    return {
      syncFormData,
      GetSubscribePlan,
      givePeriodOptions,
      showFields,
      fixedDateOptions,
      onPeriodTypeChange,
      displayPeriodPreviewText,
      weekDayOptions,
      previewSubscriptionPeriod,
      showPeriodOverflowHint,
    }
  },
})

</script>

<style lang="postcss" scoped>
</style>
